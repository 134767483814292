<template>
  <div
    class="onboarding-bg flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8 min-vh-100 h-screen"
  >
    <div class="sm:mx-auto max-w-2xl">
      <div
        class="login-card px-6 py-12 shadow sm:rounded-lg sm:px-12"
        v-if="step == 1"
      >
        <h2
          class="mb-6 text-center text-3xl font-extrabold leading-9 tracking-tight text-white login-heading"
        >
          Sign Up for Competition <br />
          {{ challenge }} Challenge
        </h2>

        <form class="space-y-6" @submit.prevent="submit()">
          <div>
            <label
              for="email"
              class="block text-sm font-medium leading-6 text-white text-left"
              >Email address</label
            >
            <div class="mt-2">
              <input
                v-model="email"
                name="email"
                type="email"
                autocomplete="email"
                required
                class="px-2 block w-full rounded-md border-0 py-2.5 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <label
              for="phone"
              class="block text-sm font-medium leading-6 text-white text-left"
              >Phone Number</label
            >
            <div class="mt-2">
              <input
                v-model="phone"
                name="phone"
                required
                type="text"
                class="px-2 block w-full rounded-md border-0 py-2.5 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div>
            <button
              type="submit"
              :disabled="isDisabled"
              :class="{ 'disabled-btn cursor-not-allowed': isDisabled }"
              class="login-btn flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Sign up
              <svg
                v-if="isLoading"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                data-slot="icon"
                class="w-6 h-6 animate-spin"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                />
              </svg>
            </button>
          </div>
          <div class="flex items-center justify-center">
            <div class="text-sm leading-6 text-white">
              <router-link to="/login">Already have an account?</router-link>
            </div>
          </div>
        </form>
      </div>
      <Otp @updateStep="updateStep" v-if="step == 2" />
      <Welcome @updateStep="updateStep" v-if="step == 3" />
      <SuccessOnboardingPage v-if="step == 4" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Otp from "./Otp.vue";
import Welcome from "./Welcome.vue";
import SuccessOnboardingPage from "./SuccessOnboardingPage.vue";
export default {
  components: { Otp, Welcome, SuccessOnboardingPage },
  data() {
    return {
      email: "",
      phone: "",
      challenge: "",
      step: 1,
      isLoading: false,
    };
  },
  mounted() {
    if (
      this.$route.query.c &&
      this.$route.query.product_id &&
      this.$route.query.challenge_type
    ) {
      this.challenge = this.$route.query.c;
    } else {
      this.$router.push({
        name: "NotFound",
      });
    }
  },
  methods: {
    async submit() {
      this.isLoading = true;
      let formData = new FormData();
      formData.append("grant_type", "client_credentials");
      formData.append("client_id", process.env.VUE_APP_HS_CLIENT_ID);
      formData.append("client_secret", process.env.VUE_APP_HS_CLIENT_SECRET);
      formData.append("scope", "contact deal otp");

      await axios
        .post(`${process.env.VUE_APP_API_URL}/oauth/token`, formData)
        .then((response) => {
          if (response.status == 200) {
            localStorage.setItem("token", response.data.access_token);
            this.checkForExistingUser();
          }
        })
        .catch((err) => {
          alert("ERROR: ", err.response.data.error.message ?? err);
          this.isLoading = false;
        });
    },
    async checkForExistingUser() {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      };

      await axios
        .get(
          `${
            process.env.VUE_APP_API_URL
          }/api/v1/contacts/filter?email=${encodeURIComponent(this.email)}`,
          // bodyParameters,
          config
        )
        .then((response) => {
          console.log("response: ", response);

          if (response.status == 200) {
            alert("This email address is already taken. Please log in.");
            this.isLoading = false;

            this.$router.push({
              name: "CompetitionLogin",
              query: {
                product_id: this.$route.query.product_id,
                quantity: 1,
                challenge_type: this.$route.query.challenge_type,
                c: this.$route.query.c,
              },
            });
          } else if (response.status == 404) {
            this.sendOtp();
          }
        })
        .catch((err) => {
          console.log("ERROR: ", err);
          if (err.response.status == 404) {
            this.sendOtp();
          } else {
            alert("This email address is already taken. Please log in.");
            this.isLoading = false;
            this.$router.push({ name: "CompetitionLogin" });
          }
        });
    },
    async sendOtp() {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      };

      const bodyParameters = {
        email: this.email,
        session_id: crypto.randomUUID(),
      };

      await axios
        .post(
          `${process.env.VUE_APP_API_URL}/api/v1/otp`,
          bodyParameters,
          config
        )
        .then((response) => {
          if (response.status == 200) {
            this.isLoading = false;

            localStorage.setItem("user_email", response.data.data.email);
            localStorage.setItem("session_id", response.data.data.session_id);
            localStorage.setItem("step", 2);

            this.step = 2;
          }
        })
        .catch((err) => {
          alert("ERROR: ", err.response.data.error.message ?? err);
          this.isLoading = false;
        });
    },
    updateStep(step) {
      this.step = step;
    },
  },
  computed: {
    isDisabled() {
      return !this.email || !this.phone;
    },
  },
};
</script>

<style lang="postcss" scoped>
.onboarding-bg {
  min-height: max-content;
  background: url(../../assets/onboarding-bg.png) lightgray 50% / cover
    no-repeat;
}
.login-heading,
.link {
  color: #cce7ea;
}
.login-card {
  border-radius: 16px;
  border: 1px solid #333;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(20px);
}
input {
  border-radius: 8px;
  border: 1px solid var(--Type-Primary, #cce7ea);
  background: var(
    --Box-background,
    linear-gradient(61deg, #000 10.51%, #1d1c21 100%)
  );
  backdrop-filter: blur(20px);
}
.login-btn {
  border-radius: 8px;
  border: 2px solid var(--Button-blue, #124fd3);
  background: linear-gradient(98deg, #124fd3 0%, #2bc7f8 164.6%);

  /* Box Shadow */
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1),
    0px 3px 7px 0px rgba(0, 0, 0, 0.1), 0px 13px 13px 0px rgba(0, 0, 0, 0.09),
    0px 30px 18px 0px rgba(0, 0, 0, 0.05), 0px 53px 21px 0px rgba(0, 0, 0, 0.01),
    0px 82px 23px 0px rgba(0, 0, 0, 0);
}
.disabled-btn {
  background: linear-gradient(
    98deg,
    rgba(18, 79, 211, 0.2) 0%,
    rgba(43, 199, 248, 0.2) 164.6%
  );
  border: 0;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1),
    0px 3px 7px 0px rgba(0, 0, 0, 0.1), 0px 13px 13px 0px rgba(0, 0, 0, 0.09),
    0px 30px 18px 0px rgba(0, 0, 0, 0.05), 0px 53px 21px 0px rgba(0, 0, 0, 0.01),
    0px 82px 23px 0px rgba(0, 0, 0, 0);
}
</style>
