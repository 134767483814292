<template>
  <div
    class="onboarding-bg flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8 min-vh-100 h-screen"
  >
    <div class="mt-10 sm:mx-auto max-w-2xl">
      <div
        class="login-card px-6 py-12 shadow sm:rounded-lg sm:px-12"
        v-if="loadingState == '' && step == 1"
      >
        <h2
          class="mb-6 text-center text-3xl font-extrabold leading-9 tracking-tight text-white login-heading"
        >
          Log in for Competition <br />
          {{ challenge }} Challenge
        </h2>

        <form class="space-y-6" @submit="generateToken">
          <div>
            <label
              for="email"
              class="block text-sm font-medium leading-6 text-white text-left"
              >Email address</label
            >
            <div class="mt-2">
              <input
                v-model="email"
                type="email"
                required=""
                class="px-2 block w-full rounded-md border-0 py-2.5 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <label
              for="password"
              class="block text-sm font-medium leading-6 text-white text-left"
              >Password</label
            >
            <div class="mt-2">
              <input
                v-model="password"
                type="password"
                required=""
                class="px-2 block w-full rounded-md border-0 py-2.5 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <button
              :disabled="!(email && password)"
              :class="{
                'disabled-btn cursor-not-allowed': !(email && password),
              }"
              type="submit"
              class="login-btn flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Log in
              <svg
                v-if="isLoading"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                data-slot="icon"
                class="w-6 h-6 animate-spin"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                />
              </svg>
            </button>
          </div>
        </form>
      </div>
      <VerifyingMotion
        v-else-if="
          (loadingState == 'SUCCESSFUL' || loadingState == 'VERIFYING') &&
          step == 1
        "
        ref="verifyingMotion"
        :loadingState="loadingState"
        :loop="true"
      />
      <img
        v-else-if="loadingState == 'FAILED' && step == 1"
        class="h-60"
        :src="require(`@/assets/error.png`)"
      />
      <WelcomeBack
        @updateStep="updateStep"
        :user="auth_user"
        v-if="step == 2"
      />
      <SuccessLoginPage v-if="step == 3" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VerifyingMotion from "../../components/VerifyingMotion.vue";
import WelcomeBack from "./WelcomeBack.vue";
import SuccessLoginPage from "./SuccessLoginPage.vue";

export default {
  components: { VerifyingMotion, WelcomeBack, SuccessLoginPage },
  data() {
    return {
      challenge: "",
      isLoading: false,
      email: null,
      password: null,
      loadingState: "",
      step: 1,
      auth_user: null,
    };
  },
  mounted() {
    if (
      this.$route.query.c &&
      this.$route.query.product_id &&
      this.$route.query.challenge_type
    ) {
      this.challenge = this.$route.query.c;
    } else {
      this.$router.push({
        name: "NotFound",
      });
    }
  },
  methods: {
    async generateToken() {
      this.loadingState = "VERIFYING";
      this.$nextTick(() => {
        let self = this;
        self.$refs.verifyingMotion.restart();
      });
      this.isLoading = true;
      let formData = new FormData();
      formData.append("grant_type", "client_credentials");
      formData.append("client_id", process.env.VUE_APP_HS_CLIENT_ID);
      formData.append("client_secret", process.env.VUE_APP_HS_CLIENT_SECRET);
      formData.append("scope", "contact deal otp");

      await axios
        .post(`${process.env.VUE_APP_API_URL}/oauth/token`, formData)
        .then((response) => {
          if (response.status == 200) {
            localStorage.setItem("token", response.data.access_token);
            this.validateCredential();
          }
        })
        .catch((err) => {
          alert("ERROR: ", err.response.data.error.message ?? err);
          this.isLoading = false;
        });
    },
    async validateCredential() {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      };
      const bodyParameters = {
        email: this.email,
        password: this.password,
      };

      await axios
        .post(
          `${process.env.VUE_APP_API_URL}/api/v1/contacts/validate-credentials`,
          bodyParameters,
          config
        )
        .then((response) => {
          if (response.status == 200) {
            this.loadingState = "SUCCESSFUL";
            this.$nextTick(() => {
              let self = this;
              self.$refs.verifyingMotion.restart();
            });
            this.isLoading = false;
            this.step = 2;
            this.auth_user = response.data.data;
          }
        })
        .catch((err) => {
          console.log("ERROR: ", err);
          this.loadingState = "FAILED";
          setTimeout(() => {
            this.loadingState = "";
          }, 2000);
          this.isLoading = false;
        });
    },
    updateStep(step) {
      this.step = step;
    },
  },
};
</script>

<style lang="postcss" scoped>
.onboarding-bg {
  min-height: max-content;
  background: url(../../assets/onboarding-bg.png) lightgray 50% / cover
    no-repeat;
}
.login-heading,
.link {
  color: #cce7ea;
}
.login-card {
  border-radius: 16px;
  border: 1px solid #333;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(20px);
}
input {
  border-radius: 8px;
  border: 1px solid var(--Type-Primary, #cce7ea);
  background: var(
    --Box-background,
    linear-gradient(61deg, #000 10.51%, #1d1c21 100%)
  );
  backdrop-filter: blur(20px);
}
.login-btn {
  border-radius: 8px;
  border: 2px solid var(--Button-blue, #124fd3);
  background: linear-gradient(98deg, #124fd3 0%, #2bc7f8 164.6%);

  /* Box Shadow */
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1),
    0px 3px 7px 0px rgba(0, 0, 0, 0.1), 0px 13px 13px 0px rgba(0, 0, 0, 0.09),
    0px 30px 18px 0px rgba(0, 0, 0, 0.05), 0px 53px 21px 0px rgba(0, 0, 0, 0.01),
    0px 82px 23px 0px rgba(0, 0, 0, 0);
}
.disabled-btn {
  background: linear-gradient(
    98deg,
    rgba(18, 79, 211, 0.2) 0%,
    rgba(43, 199, 248, 0.2) 164.6%
  );
  border: 0;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1),
    0px 3px 7px 0px rgba(0, 0, 0, 0.1), 0px 13px 13px 0px rgba(0, 0, 0, 0.09),
    0px 30px 18px 0px rgba(0, 0, 0, 0.05), 0px 53px 21px 0px rgba(0, 0, 0, 0.01),
    0px 82px 23px 0px rgba(0, 0, 0, 0);
}
</style>
