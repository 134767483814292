<template>
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="w-6 h-6 absolute right-10 top-8 cursor-pointer"
      @click="$emit('setShowAvatar', false)"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>

    <h2
      class="text-center text-3xl font-extrabold leading-9 tracking-tight text-white login-heading mt-5"
    >
      Choose Your Avatar
    </h2>
    <div class="grid grid-cols-3 mt-5">
      <img
        v-for="index in 11"
        :key="index"
        class="z-10 my-1 h-32 mx-auto"
        :class="{
          'border-2 border-blue-600 rounded-full':
            selectedAvatar == `avatar-${index}.png`,
        }"
        :src="require(`@/assets/avatar/avatar-${index}.png`)"
        @click="$emit('setSelectedAvatar', `avatar-${index}.png`)"
      />
    </div>
    <button
      type="button"
      @click="$emit('setShowAvatar', false)"
      :disabled="!selectedAvatar"
      :class="{ 'disabled-btn cursor-not-allowed': !selectedAvatar }"
      class="login-btn mt-5 flex w-full justify-center rounded-md px-3 py-2.5 text-sm font-semibold leading-6 text-white shadow-sm"
    >
      Save
    </button>
  </div>
</template>

<script>
export default {
  name: "AvatarGallery",
  props: {
    selectedAvatar: String,
  },
};
</script>
