<template>
  <div class="mt-10 sm:mx-auto max-w-2xl">
    <div class="login-card px-6 py-12 shadow sm:rounded-lg sm:px-12">
      <h2
        class="mb-6 text-center text-3xl font-extrabold leading-9 tracking-tight text-white login-heading"
      >
        Welcome To We Fund You Trade!
      </h2>
      <p class="text-center text-white">
        Welcome aboard on the free trial of We Fund You Trade
        {{ challenge }} Challenge! We will send you a reminder via your email
        when the next challenge will start.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      challenge: this.$route.query.c,
    };
  },
};
</script>

<style>
.login-heading,
.link {
  color: #cce7ea;
}
.login-card {
  border-radius: 16px;
  border: 1px solid #333;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(20px);
}
.hyperlink {
  color: #5674c2;
}
</style>
