<template>
  <div
    class="onboarding-bg flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8 min-vh-100 h-screen"
  >
    <div class="sm:mx-auto min-w-4xl">
      <div class="login-card px-6 py-12 shadow sm:rounded-lg sm:px-12">
        <img
          :src="require(`@/assets/404-error.png`)"
          alt="404 Not found"
          class="h-40 mx-auto mb-6"
        />

        <h2
          class="mt-16 mb-8 text-center text-5xl font-extrabold leading-9 tracking-tight text-white login-heading"
        >
          PAGE NOT FOUND
        </h2>
        <p class="text-white mb-2">We looked everywhere for this page.</p>
        <p class="text-white mb-6">Are you sure the website URL is correct?</p>
        <a
          href="https://wfyt.com/"
          class="login-btn flex justify-center rounded-md bg-indigo-600 px-3 py-2.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Go back to the main site
        </a>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.onboarding-bg {
  min-height: max-content;
  background: url(../assets/onboarding-bg.png) lightgray 50% / cover no-repeat;
}
.login-heading,
.link {
  color: #cce7ea;
}
.login-btn {
  border-radius: 8px;
  border: 2px solid var(--Button-blue, #124fd3);
  background: linear-gradient(98deg, #124fd3 0%, #2bc7f8 164.6%);

  /* Box Shadow */
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1),
    0px 3px 7px 0px rgba(0, 0, 0, 0.1), 0px 13px 13px 0px rgba(0, 0, 0, 0.09),
    0px 30px 18px 0px rgba(0, 0, 0, 0.05), 0px 53px 21px 0px rgba(0, 0, 0, 0.01),
    0px 82px 23px 0px rgba(0, 0, 0, 0);
}
.login-card {
  border-radius: 16px;
  border: 1px solid #333;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(20px);
}
</style>
