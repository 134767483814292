<template>
  <div class="sm:mx-auto max-w-2xl">
    <div class="login-card px-6 py-12 shadow sm:rounded-lg sm:px-12">
      <AvatarGallery
        @setShowAvatar="setShowAvatar"
        @setSelectedAvatar="setSelectedAvatar"
        v-if="showAvatarChoices"
        :selectedAvatar="selectedAvatar"
      />

      <div v-else>
        <h2
          class="text-center text-3xl font-extrabold leading-9 tracking-tight text-white login-heading"
        >
          Set Up Your Profile
        </h2>
        <div class="flex" v-if="!selectedAvatar">
          <img
            class="z-10 my-6 h-36 mx-auto mt-10"
            :src="require(`@/assets/avatar/placeholder-1.png`)"
          />
          <img
            @click="showAvatarChoices = true"
            class="z-10 my-6 h-36 -mx-28 z-20 cursor-pointer"
            :src="require(`@/assets/avatar/default.png`)"
          />

          <img
            class="z-10 my-6 h-36 mx-auto mt-10"
            :src="require(`@/assets/avatar/placeholder-2.png`)"
          />
        </div>
        <div class="flex relative" v-else>
          <img
            @click="showAvatarChoices = true"
            class="my-6 h-36 mx-auto cursor-pointer"
            :src="require(`@/assets/avatar/${selectedAvatar}`)"
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6 absolute right-36 bottom-10 text-gray-200"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
            />
          </svg>
        </div>

        <form class="space-y-6" @submit.prevent="createContact()">
          <div>
            <label
              class="block text-sm font-medium leading-6 text-white text-left"
              >Nickname
            </label>
            <div class="relative mt-2 rounded-md shadow-sm">
              <input
                type="text"
                v-model="nickname"
                @focusout="checkForExistingNickname"
                @input="isLoading = true"
                :class="{
                  takenNickname:
                    isNicknameAvail === false &&
                    nickname !== null &&
                    isLoading == false,
                }"
                class="block w-full rounded-md border-0 py-2.5 px-2 text-white shadow-sm placeholder:text-gray-400 focus:ring-2 sm:text-sm sm:leading-6"
              />
              <div
                v-if="isLoading == true"
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  data-slot="icon"
                  class="w-6 h-6 animate-spin"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                  />
                </svg>
              </div>
            </div>
            <p
              v-if="nickname !== null && nickname !== '' && isLoading == false"
              class="mt-2 text-sm flex space-x-1"
            >
              <svg
                v-if="isNicknameAvail == false"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6 text-red-600"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>

              <svg
                v-if="!isNicknameAvail == false"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6 text-green-600"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <span class="text-red-600" v-if="isNicknameAvail == false">
                Nickname is already taken.
              </span>
              <span v-else class="text-green-600">
                Nickname is still available.
              </span>
            </p>
          </div>

          <div class="flex space-x-4">
            <div>
              <label
                class="block text-sm font-medium leading-6 text-white text-left"
                >First Name</label
              >
              <div class="mt-2">
                <input
                  v-model="firstname"
                  type="text"
                  required=""
                  class="block w-full rounded-md border-0 py-2.5 px-2 text-white shadow-sm placeholder:text-gray-400 focus:ring-2 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div>
              <label
                class="block text-sm font-medium leading-6 text-white text-left"
                >Last Name</label
              >
              <div class="mt-2">
                <input
                  v-model="lastname"
                  type="text"
                  required=""
                  class="block w-full rounded-md border-0 py-2.5 px-2 text-white shadow-sm placeholder:text-gray-400 focus:ring-2 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>

          <div>
            <div>
              <label
                class="block text-sm font-medium leading-6 text-white text-left"
                >Country</label
              >
              <div class="relative mt-2">
                <button
                  type="button"
                  @click="isSelectCountryOpen = !isSelectCountryOpen"
                  class="relative block w-full rounded-md border py-2.5 px-2 text-white shadow-sm placeholder:text-gray-400 focus:ring-1 focus:ring-white sm:text-sm sm:leading-6"
                  aria-haspopup="listbox"
                  aria-expanded="true"
                  aria-labelledby="listbox-label"
                >
                  <span class="flex items-center">
                    <img
                      :src="`https://flagcdn.com/w160/${countryCode}.png`"
                      class="h-5 w-8 flex-shrink-0"
                    />
                    <span class="ml-2 block truncate">{{ country }}</span>
                  </span>
                  <span
                    class="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2"
                  >
                    <svg
                      class="h-5 w-5 text-gray-400"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                </button>
                <ul
                  v-show="isSelectCountryOpen"
                  class="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                  tabindex="-1"
                  role="listbox"
                  aria-labelledby="listbox-label"
                  aria-activedescendant="listbox-option-3"
                >
                  <li
                    v-for="(country, code, index) in allCountries"
                    :key="index"
                    @click="updateSelectedCountry(code, country)"
                    class="text-gray-900 relative cursor-default select-none py-2 pl-3 pr-9"
                    id="listbox-option-0"
                    role="option"
                  >
                    <div class="flex items-center">
                      <img
                        :src="`https://flagcdn.com/w160/${code}.png`"
                        class="h-5 w-8 flex-shrink-0"
                      />
                      <span
                        class="font-normal ml-2 block truncate"
                        :class="{ 'font-semibold': countryCode == code }"
                        >{{ country }}</span
                      >
                    </div>
                    <span
                      v-if="countryCode == code"
                      class="text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4"
                    >
                      <svg
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div>
            <button
              type="submit"
              :disabled="isDisabled"
              :class="{ 'disabled-btn cursor-not-allowed': isDisabled }"
              class="login-btn flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Next
              <svg
                v-if="isSubmitBtnLoading"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                data-slot="icon"
                class="w-6 h-6 animate-spin"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                />
              </svg>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import AvatarGallery from "../../components/AvatarGallery.vue";

export default {
  components: { AvatarGallery },
  data() {
    return {
      showAvatarChoices: false,
      selectedAvatar: null,
      firstname: null,
      lastname: null,
      nickname: null,
      country: null,
      isNicknameAvail: false,
      isLoading: false,
      isSubmitBtnLoading: false,
      orderNumber: null,
      allCountries: {},
      countryCode: null,
      isSelectCountryOpen: false,
    };
  },
  mounted() {
    this.getAllCountries();
  },
  methods: {
    async checkForExistingNickname() {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      };

      if (this.nickname) {
        await axios
          .get(
            `${
              process.env.VUE_APP_API_URL
            }/api/v1/contacts/filter?nickname=${encodeURIComponent(
              this.nickname
            )}`,
            config
          )
          .then((response) => {
            if (response.status == 200) {
              this.isNicknameAvail = false;
            } else if (response.status == 404) {
              this.isNicknameAvail = true;
            }
            this.isLoading = false;
          })
          .catch((err) => {
            console.log("ERROR: ", err);
            if (err.response.status == 404) {
              this.isNicknameAvail = true;
              this.isLoading = false;
            } else if (err.response.status == 401) {
              this.regenerateToken();
            } else {
              this.isNicknameAvail = false;
              this.isLoading = false;
            }
          });
      }
    },
    async createContact() {
      this.orderNumber = "FREE" + new Date().getTime();
      this.isSubmitBtnLoading = true;
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      };

      const bodyParameters = {
        email: localStorage.getItem("user_email"),
        firstname: this.firstname,
        lastname: this.lastname,
        country: this.countryCode,
        avatar_id: this.selectedAvatar,
        nickname: this.nickname,
        last_order_order_number: this.orderNumber,
      };

      await axios
        .post(
          `${process.env.VUE_APP_API_URL}/api/v1/contacts`,
          bodyParameters,
          config
        )
        .then((response) => {
          if (response.status == 200) {
            console.log("Contact ID: ", response.data.data.hs_object_id);
            localStorage.setItem("contact_id", response.data.data.hs_object_id);
            this.createDeal();
          } else if (response.status == 409) {
            alert(response.data.error.message);
            this.isSubmitBtnLoading = false;
          }
        })
        .catch((err) => {
          console.log("ERROR: ", err);
          if (err.response.status == 409) {
            alert(err.data.error.message);
          }
          this.isSubmitBtnLoading = false;
        });
    },
    async createDeal() {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      };

      const bodyParameters = {
        name: this.firstname + " " + this.lastname,
        order_number: this.orderNumber,
        amount: 0,
        challenge_type: this.$route.query.challenge_type,
        dealstage: "Completed",
        pipeline: "Ecommerce",
      };

      await axios
        .post(
          `${process.env.VUE_APP_API_URL}/api/v1/deals`,
          bodyParameters,
          config
        )
        .then((response) => {
          if (response.status == 200) {
            console.log("Deal ID: ", response.data.data.hs_object_id);
            localStorage.setItem("deal_id", response.data.data.hs_object_id);
            this.associateDealToContact();
          } else {
            alert(response.data.error.message);
            this.isSubmitBtnLoading = false;
          }
        })
        .catch((err) => {
          console.log("ERROR: ", err);
          alert("Error: ", err.data.error.message);
          this.isSubmitBtnLoading = false;
        });
    },
    async associateDealToContact() {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      };

      const bodyParameters = {
        deal_id: localStorage.getItem("deal_id"),
        contact_id: localStorage.getItem("contact_id"),
      };

      await axios
        .post(
          `${process.env.VUE_APP_API_URL}/api/v1/deals/associate-contact`,
          bodyParameters,
          config
        )
        .then((response) => {
          if (response.status == 200) {
            this.updateFreeGift();
          } else {
            alert(response.data.error.message);
          }
          this.isSubmitBtnLoading = false;
        })
        .catch((err) => {
          console.log("ERROR: ", err);
          alert("Error: ", err.data.error.message);
          this.isSubmitBtnLoading = false;
        });
    },
    async updateFreeGift() {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      };

      const bodyParameters = {
        free_competition_entry_used_: "true",
      };

      await axios
        .put(
          `${
            process.env.VUE_APP_API_URL
          }/api/v1/contacts/${localStorage.getItem("contact_id")}`,
          bodyParameters,
          config
        )
        .then((response) => {
          if (response.status == 200) {
            this.$emit("updateStep", 4);
            localStorage.setItem("step", 4);
            this.isSubmitBtnLoading = false;
          } else {
            alert(response.data.error.message);
          }
          this.isSubmitBtnLoading = false;
        })
        .catch((err) => {
          console.log("ERROR: ", err);
          alert("Error: ", err.data.error.message);
          this.isSubmitBtnLoading = false;
        });
    },
    setShowAvatar(toggle) {
      this.showAvatarChoices = toggle;
    },
    setSelectedAvatar(avatar) {
      this.selectedAvatar = avatar;
    },
    async regenerateToken() {
      let formData = new FormData();
      formData.append("grant_type", "client_credentials");
      formData.append("client_id", process.env.VUE_APP_HS_CLIENT_ID);
      formData.append("client_secret", process.env.VUE_APP_HS_CLIENT_SECRET);
      formData.append("scope", "contact deal otp");

      await axios
        .post(`${process.env.VUE_APP_API_URL}/oauth/token`, formData)
        .then((response) => {
          if (response.status == 200) {
            localStorage.setItem("token", response.data.access_token);
            this.checkForExistingNickname();
          }
        })
        .catch((err) => {
          alert("ERROR: ", err.response.data.error.message ?? err);
        });
    },
    getAllCountries() {
      axios
        .get("https://flagcdn.com/en/codes.json")
        .then((response) => {
          console.log("response: ", response);

          if (response.status == 200) {
            this.allCountries = response.data;
            const sortedKeys = Object.keys(response.data).sort((a, b) =>
              response.data[a].localeCompare(response.data[b])
            );

            const sortedCountries = {};
            for (const key of sortedKeys) {
              sortedCountries[key] = response.data[key];
            }

            this.allCountries = sortedCountries;
            console.log("All countries: ", this.allCountries);

            this.country = Object.values(this.allCountries)[0];
            this.countryCode = Object.keys(this.allCountries)[0];
          }
        })
        .catch((err) => {
          console.log("ERROR: ", err);
        });
    },
    updateSelectedCountry(code, country) {
      this.countryCode = code;
      this.country = country;
      this.isSelectCountryOpen = !this.isSelectCountryOpen;
    },
  },
  computed: {
    isDisabled() {
      return !(
        this.nickname &&
        this.firstname !== "" &&
        this.lastname !== "" &&
        this.selectedAvatar !== null &&
        this.isNicknameAvail
      );
    },
  },
};
</script>

<style lang="postcss" scoped>
.onboarding-bg {
  min-height: max-content;
  background: url(../../assets/onboarding-bg.png) lightgray 50% / cover
    no-repeat fixed;
  height: fit-content;
}

.login-heading,
.link {
  color: #cce7ea;
}

.login-card {
  border-radius: 16px;
  border: 1px solid #333;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(20px);
}

input {
  border-radius: 8px;
  border: 1px solid var(--Type-Primary, #cce7ea);
  background: var(
    --Box-background,
    linear-gradient(61deg, #000 10.51%, #1d1c21 100%)
  );
  backdrop-filter: blur(20px);
}

.login-btn {
  border-radius: 8px;
  border: 2px solid var(--Button-blue, #124fd3);
  background: linear-gradient(98deg, #124fd3 0%, #2bc7f8 164.6%);

  /* Box Shadow */
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1),
    0px 3px 7px 0px rgba(0, 0, 0, 0.1), 0px 13px 13px 0px rgba(0, 0, 0, 0.09),
    0px 30px 18px 0px rgba(0, 0, 0, 0.05), 0px 53px 21px 0px rgba(0, 0, 0, 0.01),
    0px 82px 23px 0px rgba(0, 0, 0, 0);
}

.disabled-btn {
  background: linear-gradient(
    98deg,
    rgba(18, 79, 211, 0.2) 0%,
    rgba(43, 199, 248, 0.2) 164.6%
  );
  border: 0;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1),
    0px 3px 7px 0px rgba(0, 0, 0, 0.1), 0px 13px 13px 0px rgba(0, 0, 0, 0.09),
    0px 30px 18px 0px rgba(0, 0, 0, 0.05), 0px 53px 21px 0px rgba(0, 0, 0, 0.01),
    0px 82px 23px 0px rgba(0, 0, 0, 0);
}

.takenNickname {
  color: red;
  border: 2px solid red;
}
</style>
