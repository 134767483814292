<script setup>
import { Vue3Lottie } from "vue3-lottie";
import VerificationSuccessfulJson from "@/assets/lottiefiles/verify_success.json";
import VerifyingJson from "@/assets/lottiefiles/verifying.json";
import { defineProps, ref, onMounted, defineExpose, onUpdated } from "vue";

const props = defineProps({
  width: {
    type: String,
    default: "auto",
  },
  height: {
    type: String,
    default: "15rem",
  },
  speed: {
    type: Number,
    default: 1,
  },
  loop: {
    type: Boolean,
    default: true,
  },
  loadingState: {
    type: String,
    default: "VERIFYING",
  },
});

const verification = ref(null);
let currentJson = ref(null);

const restart = () => {
  verification.value.goToAndPlay(0, true);
};
const updateAnimation = () => {
  currentJson.value =
    props.loadingState == "VERIFYING"
      ? VerifyingJson
      : props.loadingState == "SUCCESSFUL"
      ? VerificationSuccessfulJson
      : null;
};

onMounted(() => {
  updateAnimation();
});

onUpdated(() => {
  updateAnimation();
});

defineExpose({
  restart,
});
</script>

<template>
  <div>
    <Vue3Lottie
      :animationData="currentJson"
      :width="props.width"
      :height="props.height"
      :speed="props.speed"
      :loop="props.loop"
      ref="verification"
    />
  </div>
</template>
<style lang="postcss" scoped>
/deep/div.lottie-animation-container {
  margin: 1rem auto;
}
/deep/div.lottie-animation-container svg {
  min-height: 150px;
  margin-left: auto;
  margin-right: auto;
}
</style>
