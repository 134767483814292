<template>
  <div class="mt-10 sm:mx-auto max-w-2xl">
    <div
      v-if="loadingState == ''"
      class="login-card px-6 py-12 shadow sm:rounded-lg sm:px-12"
    >
      <h2
        class="mb-6 text-center text-3xl font-extrabold leading-9 tracking-tight text-white login-heading"
      >
        Verify your code
      </h2>
      <p class="text-center text-white">
        Please input the code sent to your email:
      </p>
      <div class="flex text-center justify-center flex-wrap">
        <span class="underline hyperlink cursor-pointer" @click="forceReload"
          >{{ userEmail }}
        </span>

        <span class="text-white pl-1"
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-4 h-4 pt-1"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
            />
          </svg>
        </span>
      </div>
      <div class="otp-input-container h-28 flex flex-center justify-center">
        <v-otp-input
          ref="otpInput"
          v-model:value="bindModal"
          input-classes="otp-input"
          :num-inputs="6"
          :should-auto-focus="true"
          separator=""
          input-type="number"
          @on-complete="handleOnComplete"
        />
      </div>
      <div class="flex items-center justify-center">
        <div class="text-sm leading-6">
          <button
            v-if="enableResend"
            @click="resendOtp"
            class="font-bold hyperlink underline"
          >
            Resend OTP
          </button>

          <p v-else class="text-white">
            Resend in
            <span class="text-red-400">
              {{ minutes }} min {{ seconds }} sec
            </span>
          </p>
        </div>
      </div>
    </div>
    <VerifyingMotion
      v-else-if="loadingState == 'SUCCESSFUL' || loadingState == 'VERIFYING'"
      ref="verifyingMotion"
      :loadingState="loadingState"
      :loop="true"
    />
    <img
      v-else-if="loadingState == 'FAILED'"
      class="h-60 mx-auto"
      :src="require(`@/assets/error.png`)"
    />
  </div>
</template>

<script>
import axios from "axios";
import VerifyingMotion from "../../components/VerifyingMotion.vue";

export default {
  components: { VerifyingMotion },
  data() {
    return {
      isDisabled: true,
      bindModal: "",
      minutes: 2,
      seconds: 0,
      timer: null,
      enableResend: false,
      userEmail: null,
      loadingState: "",
    };
  },
  mounted() {
    this.startTimer();
    this.userEmail = localStorage.getItem("user_email");
  },
  methods: {
    handleOnComplete() {
      this.loadingState = "VERIFYING";
      this.$nextTick(() => {
        let self = this;
        self.$refs.verifyingMotion.restart();
      });
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      };

      const bodyParameters = {
        email: localStorage.getItem("user_email"),
        session_id: localStorage.getItem("session_id"),
        code: this.bindModal,
      };

      axios
        .post(
          `${process.env.VUE_APP_API_URL}/api/v1/otp/validate`,
          bodyParameters,
          config
        )
        .then((response) => {
          if (response.status == 200) {
            this.loadingState = "SUCCESSFUL";
            this.$nextTick(() => {
              let self = this;
              self.$refs.verifyingMotion.restart();
            });
            setTimeout(() => {
              this.$emit("updateStep", 3);
              localStorage.setItem("step", 3);
            }, 2000);
          }
        })
        .catch((err) => {
          this.loadingState = "FAILED";
          setTimeout(() => {
            this.loadingState = "";
            this.bindModal = "";
          }, 2000);

          console.log("ERROR: ", err.response.data.error.message ?? err);
        });
    },
    startTimer() {
      this.enableResend = false;

      this.timer = setInterval(() => {
        if (this.minutes === 0 && this.seconds === 0) {
          clearInterval(this.timer);
          this.enableResend = true;
        } else {
          if (this.seconds === 0) {
            this.minutes--;
            this.seconds = 59;
          } else {
            this.seconds--;
          }
        }
      }, 1000);
    },
    async resendOtp() {
      await axios
        .post(`${process.env.VUE_APP_API_URL}/api/v1/otp`, {
          params: {
            email: localStorage.getItem("user_email"),
            session_id: crypto.randomUUID(),
          },
        })
        .then((response) => {
          console.log("response: ", response);
          if (response.status == 200) {
            console.log("nasend na");
          }
        })
        .catch((err) => {
          console.log("ERROR: ", err);
        });
      this.minutes = 2;
      this.startTimer();
    },
    forceReload() {
      window.location.reload();
    },
  },
};
</script>

<style>
.onboarding-bg {
  min-height: max-content;
  background: url(../../assets/onboarding-bg.png) lightgray 50% / cover
    no-repeat;
}
.login-heading,
.link {
  color: #cce7ea;
}
.login-card {
  border-radius: 16px;
  border: 1px solid #333;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(20px);
}
input {
  border-radius: 8px;
  border: 1px solid var(--Type-Primary, #cce7ea);
  background: var(
    --Box-background,
    linear-gradient(61deg, #000 10.51%, #1d1c21 100%)
  );
  backdrop-filter: blur(20px);
}
.login-btn {
  border-radius: 8px;
  border: 2px solid var(--Button-blue, #124fd3);
  background: linear-gradient(98deg, #124fd3 0%, #2bc7f8 164.6%);

  /* Box Shadow */
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1),
    0px 3px 7px 0px rgba(0, 0, 0, 0.1), 0px 13px 13px 0px rgba(0, 0, 0, 0.09),
    0px 30px 18px 0px rgba(0, 0, 0, 0.05), 0px 53px 21px 0px rgba(0, 0, 0, 0.01),
    0px 82px 23px 0px rgba(0, 0, 0, 0);
}
.disabled-btn {
  background: linear-gradient(
    98deg,
    rgba(18, 79, 211, 0.2) 0%,
    rgba(43, 199, 248, 0.2) 164.6%
  );
  border: 0;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1),
    0px 3px 7px 0px rgba(0, 0, 0, 0.1), 0px 13px 13px 0px rgba(0, 0, 0, 0.09),
    0px 30px 18px 0px rgba(0, 0, 0, 0.05), 0px 53px 21px 0px rgba(0, 0, 0, 0.01),
    0px 82px 23px 0px rgba(0, 0, 0, 0);
}
.hyperlink {
  color: #5674c2;
}
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
  background: #e4e4e4;
}
/* Background colour of an input field with value */
.otp-input.is-complete {
  background-color: #e4e4e4;
}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input::placeholder {
  font-size: 15px;
  text-align: center;
  font-weight: 600;
}
</style>
